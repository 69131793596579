import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactNode,
} from 'react';
import { Dropbox, DropboxAuth } from 'dropbox';
import { parseQueryString } from '~/src/utils/windowLocationUtil';
import { SpinningSyncIcon } from '~/src/components/icons/SpinningSyncIcon';
import IBookmark from '~/src/types/IBookmark';
import { parseFile } from '~/src/service/file-service';
import Button from '../elements/button/Button';

const dboxAccessToken = 'dbox-access-token';
const clientId = 'u9bp7y0a2vxnh1q';

const MainWrapper: React.FC<{
  className?: string;
  children: ReactNode;
}> = ({ className, children }) => (
  <div className={className}>Dropbox:&nbsp;{children}</div>
);

interface Props {
  className: string;
  bookmarks: IBookmark[];
  onBookmarksLoad: (bookmarks: IBookmark[]) => void;
}

export const Auth: React.FC<Props> = ({
  className,
  bookmarks,
  onBookmarksLoad,
}) => {
  const [dbx, setDbx] = useState(null);

  const [authUrl, setAuthUrl] = useState(null);

  const [syncing, setSyncing] = useState(false);
  const [syncingError, setSyncingError] = useState(null);

  const firstUpdate = useRef(true);

  useEffect(() => {
    const localStorageAccessToken = localStorage.getItem(dboxAccessToken);
    if (localStorageAccessToken) {
      const dbxInstance = new Dropbox({ accessToken: localStorageAccessToken });
      setDbx(dbxInstance);
      loadBookmarks(dbxInstance);
      return;
    }

    const accessTokenFromUrl = parseQueryString(
      window.location.hash,
    ).access_token;

    if (accessTokenFromUrl) {
      localStorage.setItem(dboxAccessToken, accessTokenFromUrl);
      setDbx(new Dropbox({ accessToken: accessTokenFromUrl }));
      window.location.href = '/';
      return;
    }

    const redirectUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://bookmarks.bha.ee'
        : 'http://localhost:1234';

    new DropboxAuth({ clientId, fetch })
      .getAuthenticationUrl(redirectUrl)
      .then((authUrl: string) => {
        setAuthUrl(authUrl);
      });
  }, []);

  const uploadFiles = useCallback(async () => {
    if (dbx) {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }

      setSyncing(true);
      try {
        await dbx.filesUpload({
          contents: new Blob([JSON.stringify(bookmarks)], {
            type: 'text/plain',
          }),
          path: '/bookmarks.pb',
          mode: { '.tag': 'overwrite' },
        });
      } catch (err) {
        console.error('Could not save bookmarks:');
        console.error(err);
        setSyncingError(err.message);
      }
      setSyncing(false);
    }
  }, [bookmarks]);

  useEffect(() => {
    uploadFiles();
  }, [uploadFiles]);

  const loadBookmarks = useCallback(async (dbxInstance: Dropbox) => {
    setSyncing(true);
    try {
      const response = await dbxInstance.filesDownload({
        path: '/bookmarks.pb',
      });

      onBookmarksLoad(
        await parseFile(
          // TODO: correct typing
          (response.result as unknown as { fileBlob: File }).fileBlob,
        ),
      );
    } catch (err) {
      console.error('Could not load bookmarks: ', err);
      setSyncingError(err.message);
    }
    setSyncing(false);
  }, []);

  const logOut = () => {
    localStorage.removeItem(dboxAccessToken);
    setDbx(null);
    window.location.reload();
  };

  if (!dbx) {
    return (
      <MainWrapper className={className}>
        <a href={authUrl}>authenticate</a>
      </MainWrapper>
    );
  }

  if (syncing) {
    return (
      <MainWrapper className={className}>
        <SpinningSyncIcon />
      </MainWrapper>
    );
  }

  if (syncingError) {
    return (
      <MainWrapper className={className}>
        <span className="icon has-text-danger" data-tooltip={syncingError}>
          <i className="material-icons">sync_problem</i>
        </span>
        <Button small onClick={logOut}>
          Log out
        </Button>
      </MainWrapper>
    );
  }

  return (
    <MainWrapper className={className}>
      <span className="icon has-text-success" data-tooltip="Synced">
        <i className="material-icons">check</i>
      </span>
      <Button small onClick={logOut}>
        Log out
      </Button>
    </MainWrapper>
  );
};
