import React from 'react';
import { withStyles } from '@material-ui/styles';

export const SpinningSyncIcon = withStyles({
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-360deg)' },
  },
  icon: {
    transition: 'transform 300ms',
    animation: '$spin 1s linear infinite',
    // Some default value that looks fine waiting for the animation to kicks in.
    strokeDasharray: '80px, 200px',
    strokeDashoffset: '0px', // Add the unit to fix a Edge 16 and below bug.
  },
})(({ classes }) => (
  <span className={classes.icon + '  icon'}>
    <i className="material-icons">sync</i>
  </span>
));
