import { adoptBookmarks } from './../utils/migration-util';
import IBookmark from '~/src/types/IBookmark';

export const parseFile = (file: File): Promise<IBookmark[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileContent = reader.result;

      if (typeof fileContent === 'string') {
        try {
          const bookmarks = JSON.parse(fileContent);
          resolve(adoptBookmarks(bookmarks));
        } catch (err) {
          reject(`Could not read: ${err}`);
        }
      }
    };
    reader.readAsText(file);
  });
};
