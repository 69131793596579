import React from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  small?: boolean;
  icon?: string;
}

const Button: React.FC<Props> = ({
  small,
  icon,
  children,
  className,
  ...props
}) => (
  <button
    className={`button ${className} ${small ? 'is-small' : ''}`}
    {...props}
  >
    {icon && (
      <span className="icon mr-08">
        <i className="material-icons">{icon}</i>
      </span>
    )}
    {children}
  </button>
);

export default Button;
