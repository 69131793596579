import React from 'react';

const siteInitials = (siteUrl: string): string => {
  let url = siteUrl;

  const wwwIndex = url.indexOf('//www.');

  if (wwwIndex > -1) {
    url = url.substring(wwwIndex + 6);
  } else {
    url = url.substring(8);
  }

  const firstSlash = url.indexOf('/');

  if (firstSlash > -1) {
    url = url.substring(0, firstSlash);
  }

  url = url.substring(0, url.indexOf('.'));

  return url.substring(0, 3);
};

interface Props {
  url: string;
}

export const SiteImage: React.FC<Props> = ({ url }) => (
  <div
    className={`has-background-link  has-text-white is-size-4 is-uppercase is-family-monospace p-05`}
  >
    {siteInitials(url)}
  </div>
);
