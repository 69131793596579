import React, { useEffect, useState, useReducer } from 'react';
import { loadBookmarks, saveBookmarks } from '../../service/storage-service';
import IBookmark from '../../types/IBookmark';
import { Auth } from '../auth/Auth';
import { Bookmark } from './bookmark/Bookmark';
import { ImportExport } from '../import-export/importExport';
import { FormInput } from '../elements/form/FormInput';
import { BookmarkDialog } from './add-bookmark/BookmarkDialog';
import { Modal } from '../elements/modal/Modal';
import Button from '../elements/button/Button';

export const Bookmarks: React.FC<Record<string, never>> = () => {
  const [bookmarks, setBookmarks] = useReducer(
    (prevState: IBookmark[], newState: IBookmark[]) => {
      saveBookmarks(newState);
      return newState;
    },
    [],
  );
  const [searchText, setSearchText] = useState<string>('');
  const [bookmarkDialog, setBookmarkDialog] = useState<{
    title?: string;
    bookmark?: IBookmark;
    onOk?: (bookmark: IBookmark) => void;
  } | null>(null);
  const [bookmarkRemoveDialog, setBookmarkRemoveDialog] = useState<{
    show: boolean;
    title?: string;
    bookmark?: IBookmark;
  }>({
    show: false,
  });

  useEffect(() => {
    setBookmarks(loadBookmarks());
  }, []);

  function addBookmark(bookmark: IBookmark) {
    if (bookmarks.some((item) => item.url === bookmark.url)) {
      alert('This bookmark already exists');
    } else {
      const newBookmarks = [...bookmarks, bookmark];
      setBookmarks(newBookmarks);
      hideBookmarkDialog();
    }
  }

  function updateBookmark(bookmark: IBookmark) {
    const newBookmarks = [...bookmarks];

    const foundIndex = newBookmarks.findIndex(
      (item: IBookmark) => item.id === bookmark.id,
    );

    if (foundIndex) {
      newBookmarks[foundIndex] = bookmark;
      setBookmarks(newBookmarks);
      hideBookmarkDialog();
    }
  }

  function showAddBookmarkDialog() {
    setBookmarkDialog({
      title: 'Add new',
      bookmark: null,
      onOk: addBookmark,
    });
  }

  function showEditBookmarkDialog(bookmark: IBookmark) {
    setBookmarkDialog({
      title: 'Edit',
      bookmark,
      onOk: updateBookmark,
    });
  }

  function hideBookmarkDialog() {
    setBookmarkDialog(null);
  }

  function showBookmarkRemoveConfirmation(bookmarkToRemove: IBookmark) {
    setBookmarkRemoveDialog({
      show: true,
      title: `Do you really want to remove '${bookmarkToRemove.title}'?`,
      bookmark: bookmarkToRemove,
    });
  }

  function hideBookmarkRemoveDialog() {
    setBookmarkRemoveDialog({ show: false, title: null, bookmark: null });
  }

  function removeBookmark() {
    const bkmrks = [...bookmarks];
    const index = bkmrks.indexOf(bookmarkRemoveDialog.bookmark);
    bkmrks.splice(index, 1);
    setBookmarks(bkmrks);
    hideBookmarkRemoveDialog();
  }

  function filteredBookmarks(text: string) {
    const lowerCaseText = text.toLowerCase();
    return bookmarks.filter(
      (bookmark: IBookmark) =>
        bookmark.title.toLowerCase().includes(lowerCaseText) ||
        bookmark.url.toLowerCase().includes(lowerCaseText),
    );
  }

  return (
    <div className="Bookmarks">
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <Button small icon="add" onClick={showAddBookmarkDialog}>
              <span>NEW</span>
            </Button>
          </div>
        </div>
        <div className="level-right">
          <Auth
            className="level-item"
            bookmarks={bookmarks}
            onBookmarksLoad={setBookmarks}
          />
          <ImportExport
            className="level-item"
            bookmarks={bookmarks}
            onBookmarksImport={setBookmarks}
          />
        </div>
      </div>

      <div className="control has-icons-left">
        <FormInput
          placeholder="Search..."
          type="search"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <span className="icon is-small is-left">
          <i className="material-icons">search</i>
        </span>
      </div>

      {filteredBookmarks(searchText).map((bookmark) => (
        <Bookmark
          className="mt-1"
          key={bookmark.id}
          bookmark={bookmark}
          onEdit={() => showEditBookmarkDialog(bookmark)}
          onRemove={showBookmarkRemoveConfirmation}
        />
      ))}

      {bookmarkDialog && (
        <BookmarkDialog
          title={bookmarkDialog.title}
          bookmark={bookmarkDialog.bookmark}
          onSave={bookmarkDialog.onOk}
          onCancel={hideBookmarkDialog}
        />
      )}
      {bookmarkRemoveDialog && (
        <Modal
          show={bookmarkRemoveDialog.show}
          onOk={removeBookmark}
          onCancel={hideBookmarkRemoveDialog}
        >
          {bookmarkRemoveDialog.title}
        </Modal>
      )}
    </div>
  );
};
