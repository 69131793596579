import React from 'react';

export const Navbar: React.FC<Record<string, never>> = () => {
  return (
    <div
      className="navbar is-dark"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <div className="navbar-item">
          <i className="material-icons">menu_book</i>
          <div className="ml-1">Progressive bookmarks</div>
        </div>
      </div>
    </div>
  );
};
