import React from 'react';
import { Bookmarks } from './components/bookmarks/Bookmarks';
import { Navbar } from './components/navbar/Navbar';

const App: React.FC<Record<string, never>> = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="section">
        <div className="container">
          <Bookmarks />
        </div>
      </div>
    </div>
  );
};

export default App;
