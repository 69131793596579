import React from 'react';
import { useForm } from 'react-hook-form';
import IBookmark from '~/src/types/IBookmark';
import { Modal } from '~/src/components/elements/modal/Modal';
import { FormInput } from '~/src/components/elements/form/FormInput';
import { nanoid } from 'nanoid';

interface Props {
  title: string;
  bookmark?: IBookmark | null;
  onSave: (bookmark: IBookmark) => void;
  onCancel: () => void;
}

export const BookmarkDialog: React.FC<Props> = ({
  title,
  bookmark,
  onSave,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { ref: titleRef, ...titleRest } = register('title', {
    required: true,
    minLength: 3,
  });
  const { ref: urlRef, ...urlRest } = register('url', {
    required: true,
    pattern:
      /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,10}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/,
  });

  function save(data: { url: string; title: string }) {
    // TODO: type it
    const isHttp = data.url.startsWith('http://');
    const isHttps = data.url.startsWith('https://');

    const httUrl = isHttp || isHttps ? data.url : `https://${data.url}`;
    onSave({ id: bookmark?.id || nanoid(), title: data.title, url: httUrl });
  }

  return (
    <Modal
      show={true}
      header={title}
      onOk={handleSubmit(save)}
      onCancel={onCancel}
    >
      <form onSubmit={handleSubmit(save)}>
        <FormInput
          label="Title"
          placeholder="Enter bookmark title"
          defaultValue={bookmark?.title || ''}
          type="text"
          isDanger={!!errors?.title}
          {...titleRest}
          innerRef={titleRef}
        />
        {errors?.title && (
          <p className="help is-danger">
            This field should be at least 3 characters long
          </p>
        )}
        <FormInput
          label="Url"
          placeholder="Enter bookmark url"
          defaultValue={bookmark?.url || ''}
          type="text"
          isDanger={!!errors?.url}
          inputMode="url"
          {...urlRest}
          innerRef={urlRef}
        />
        {errors?.url && <p className="help is-danger">Url is not valid</p>}

        <input type="submit" style={{ display: 'none' }} />
      </form>
    </Modal>
  );
};
