import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '../button/Button';

const useStyles = makeStyles({
  modalFooter: {
    justifyContent: 'flex-end',
  },
});

interface Props {
  show: boolean;
  header?: ReactNode;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  onOk: () => void;
  onCancel: () => void;
  children?: ReactNode;
}

export const Modal: React.FC<Props> = ({
  show,
  header,
  okDisabled,
  onOk,
  cancelDisabled,
  onCancel,
  children,
}) => {
  const classes = useStyles();

  const modal = (
    <div className="modal is-active">
      <div className="modal-background" onClick={onCancel} />
      <div className="modal-card">
        {header && <div className="modal-card-head">{header}</div>}
        {children && <section className="modal-card-body">{children}</section>}
        <footer className={`${classes.modalFooter} modal-card-foot`}>
          {onOk && (
            <Button
              className="is-success"
              small
              onClick={onOk}
              disabled={okDisabled}
            >
              OK
            </Button>
          )}
          {onCancel && (
            <Button small onClick={onCancel} disabled={cancelDisabled}>
              Cancel
            </Button>
          )}
        </footer>
      </div>
    </div>
  );

  return show && modal;
};
