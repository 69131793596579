import React from 'react';
import { SiteImage } from '~/src/components/site-image/SiteImage';
import { makeStyles } from '@material-ui/styles';
import IBookmark from '../../../types/IBookmark';
import Button from '../../elements/button/Button';

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
  content: {
    padding: '1rem',
  },
});

interface Props {
  className: string;
  bookmark: IBookmark;
  onEdit: (bookmark: IBookmark) => void;
  onRemove: (bookmark: IBookmark) => void;
}

export const Bookmark: React.FC<Props> = ({
  bookmark,
  onEdit,
  onRemove,
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${className} card`}>
      <div className={`${classes.content} card-content`}>
        <div className="media">
          <figure className="media-left">
            <a href={bookmark.url} target="_blank" rel="noopener noreferrer">
              <SiteImage url={bookmark.url} />
            </a>
          </figure>

          <div className="media-content">
            <div className="content">
              <div className="is-size-5">{bookmark.title}</div>
              <a href={bookmark.url} target="_blank" rel="noopener noreferrer">
                {bookmark.url}
              </a>
            </div>
          </div>

          <div className="media-right">
            {onEdit && (
              <Button
                className="is-success is-inverted"
                small
                onClick={() => onEdit(bookmark)}
              >
                <span className="icon is-small">
                  <i className="material-icons is-size-6">edit</i>
                </span>
              </Button>
            )}
            {onRemove && (
              <Button
                className="is-danger is-inverted"
                small
                onClick={() => onRemove(bookmark)}
              >
                <span className="icon is-small">
                  <i className="material-icons is-size-5">delete_outline</i>
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
