import React, { ChangeEvent, useRef, useState } from 'react';
import IBookmark from '~/src/types/IBookmark';
import { parseFile } from '~/src/service/file-service';
import Button from '../elements/button/Button';

interface Props {
  className: string;
  bookmarks: IBookmark[];
  onBookmarksImport: (bookmarks: IBookmark[]) => void;
}

export const ImportExport: React.FC<Props> = ({
  className,
  bookmarks,
  onBookmarksImport,
}) => {
  const [isActive, setIsActive] = useState(false);

  const inputFile = useRef(null);

  const importBookmarks = () => {
    inputFile.current.click();
  };

  const importFile = async (e: ChangeEvent) => {
    if (!(e.target instanceof HTMLInputElement)) return;

    const filesList = e.target.files;
    if (filesList.length > 0) {
      try {
        onBookmarksImport(await parseFile(filesList[0]));
      } catch (err) {
        console.error(err);
        alert('Bookmarks file is invalid or corrupt!');
      }
    }
  };

  const collapseDropdown = () => {
    setIsActive(false);
  };

  const exportBookmarks = () => {
    const file = new Blob([JSON.stringify(bookmarks)], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = 'bookmarks.pb';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={className}>
      <div className="is-hidden-mobile">
        <Button small className="mr-1" onClick={importBookmarks}>
          <span />
          <span className="icon mr-1">
            <i className="material-icons">cloud_upload</i>
          </span>
          <span>IMPORT</span>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={importFile}
            style={{ display: 'none' }}
          />
        </Button>
        <Button small onClick={exportBookmarks}>
          <span />
          <span className="icon mr-1">
            <i className="material-icons">cloud_download</i>
          </span>
          <span>EXPORT</span>
        </Button>
      </div>

      <div
        className={`dropdown is-right is-hidden-tablet${
          isActive ? ' is-active' : ''
        }`}
      >
        <div className="dropdown-trigger">
          <Button
            small
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => setIsActive(!isActive)}
            onBlur={collapseDropdown}
          >
            <span>...</span>
            <span className="icon is-small">
              <i className="material-icons" aria-hidden="true">
                arrow_drop_down
              </i>
            </span>
          </Button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            <a href="" className="dropdown-item" onClick={importBookmarks}>
              <span>Import</span>
              <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={importFile}
                style={{ display: 'none' }}
              />
            </a>
            <a href="#" className="dropdown-item" onClick={exportBookmarks}>
              Export
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
