import IBookmark from '../types/IBookmark';
import { adoptBookmarks } from '../utils/migration-util';

function loadBookmarks(): IBookmark[] {
  const bookmarks = localStorage.getItem('progressive-bookmarks_bookmarks');

  if (!bookmarks) {
    return [];
  }

  const bookmarksJson = JSON.parse(bookmarks);

  return adoptBookmarks(bookmarksJson);
}

function saveBookmarks(bookmarks: IBookmark[]): void {
  localStorage.setItem(
    'progressive-bookmarks_bookmarks',
    JSON.stringify(bookmarks),
  );
}

export { loadBookmarks, saveBookmarks };
