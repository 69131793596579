import React, { InputHTMLAttributes, LegacyRef } from 'react';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  innerRef?: LegacyRef<HTMLInputElement>;
  isDanger?: boolean | string;
  label?: string;
}

export const FormInput: React.FC<Props> = ({
  label,
  innerRef,
  isDanger,
  ...rest
}) => {
  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <input
          ref={innerRef}
          className={`input${isDanger ? ' is-danger' : ''}`}
          {...rest}
        />
      </div>
    </div>
  );
};
