import IBookmark from '~/src/types/IBookmark';
import { nanoid } from 'nanoid';

export const adoptBookmarks = (
  bookmarks: Partial<IBookmark>[],
): IBookmark[] => {
  return bookmarks.map((bookmark: IBookmark) => {
    if (!bookmark.id) {
      return { ...bookmark, id: nanoid() };
    } else {
      return bookmark;
    }
  });
};
